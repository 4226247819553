html {
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  box-sizing: border-box;
}
div {
  box-sizing: border-box;
  /* height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu {
  display: flex;
  flex-direction: row;
  height: 80px;
}

.page-title {
  margin: 1.5em;
}

.page-block {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  max-width: 1600px;
  justify-content: center;
  align-self: center;
  /* height: 100%; */
  box-shadow: 0px 0px 6px 0px #999;
}

.main-block {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  /* height: 100%; */
}

.home-block {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  justify-content: center;
  align-self: center;
}

.esperanza-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px 0px;
}

.esperanza-intro {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
}

.esperanza-block:nth-child(2n + 3) {
  background-color: #fafafa;
}

.esperanza-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 25px;
  justify-content: center;
  align-self: center;
  order: 0;
}

.item-1,
.item-2 {
  order: 1;
}
.item-text {
  order: 0;
}
.carousel-inner {
  height: 100%;
}

.services-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  background-color: #efefef;
  /* padding: 10px 0; */
}

@media (max-width: 65rem) {
  /* styles for any device that has a display width of 65rem or less */
}

@media screen and (min-width: 800px) {
  .esperanza-block {
    flex-direction: row;
  }
  .esperanza-item {
    width: 45%;
    max-height: 500px;
    flex-wrap: nowrap;
  }
  .services-block {
    display: flex;
  }
  .item-1 {
    order: 1;
  }
  .item-2 {
    order: 2;
  }
}
