.contact-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.map-container {
  width: 100%;
  padding: 0 2em 2em 2em;
}

.contact-info {
  width: 100%;
  padding: 2em;
}

.contact-info div {
  padding: 5px;
  font-size: 1.2em;
}

.gmap,
.gmap #map {
  box-shadow: 0px 0px 3px 0px #bbb;
  height: 400px;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .contact-info {
    width: 40%;
  }
  .map-container {
    width: 60%;
    padding: 2em;
  }
}
