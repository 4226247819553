.compounding-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  max-width: 90%;
}

.compounding-block .text {
  padding: 1em;
  font-size: 1.1em;
}

.compounding-block .images {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.compounding-block .images img {
  padding: 0.2em;
  display: block;
  align-self: center;
  max-height: 500px;
  width: 90%;
  object-fit: contain;
  box-sizing: border-box;
}

@media screen and (min-width: 800px) {
  .compounding-block .text {
    padding: 2em;
  }
  
  .compounding-block .images {
    flex-direction: row;
  }
  
  .compounding-block .images img {
    padding: 2em;
    width: 50%;
  }
}
