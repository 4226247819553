.app-bar {
  display: flex;
  justify-content: center;
  height: 80px;
}

.menu-icon {
  /* margin-left: 100px; */
  height: 80px;
  padding: 5px;
  margin: 0 20px;
}

.logo {
}

.menu-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 6px -6px #222;
  position: relative;
  flex-wrap: wrap;
  flex-grow: 1;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 90%;
  max-width: 1600px;
  padding: 5px;
}

.menu-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.menu-nav-button {
  align-self: flex-start;
  padding: 21px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}
.btn-nav-open {
  background-color: #ffffffdd;
  opacity: 1;
  max-height: 500px;
  transition-property: max-height, opacity;
  transition: 0.5s ease-out;
  z-index: 1;
}
.btn-nav-close {
  opacity: 0;
  max-height: 0px;
  transition-property: max-height, opacity;
  transition: 0.5s ease-in;
  z-index: -1;
}
.menu-item,
.menu-item > a {
  display: flex;
  align-self: center;
  padding: 10px;
  text-transform: uppercase;
  text-decoration: none;
}

.menu-link {
  width: 100%;
}

.menu-item-text {
  font-size: 1rem;
  font-weight: 400;
  color: #007bff;
}

@media screen and (min-width: 800px) {
  .menu-container {
    flex-wrap: nowrap;
    max-width: 1600px;
    flex-grow: 1;
  }
  .menu-nav-button {
    display: none;
  }
  .btn-nav-close {
    display: flex;
    opacity: 1;
    max-height: 500px;
  }
  .menu-navigation {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
    width: auto;
    z-index: 1;
  }
}
